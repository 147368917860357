<style lang="less" scoped>
	img{
	  width: 100%;
	  height: 100%;
	}
.item {
  margin-top: 10px;
  margin-right: 40px;
}
.none {
  font-size: 14px !important;
  text-align: center;
  margin-top: 50px;
}
.msgActive {
  color: #0090f0;
}
.logo {
  width: 125px;
  height: 50px;
  cursor: pointer;
}
.over {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.active {
  color: #0090f0;
  border-top: 4px solid #0090f0;
  height: 56px;
  line-height: 52px;
}
.active2 {
  background-color: #e5f4fd;
  color: #0090f0;
  font-size: 14px;
}
.w {
  width: 1300px;
  margin: 0 auto;
}
@deep: ~">>>";
input::-webkit-input-placeholder {
  color: #999;
  font-size: 14px;
}
@deep: ~">>>";
input::-moz-input-placeholder {
  color: #999;
  font-size: 14px;
}
@deep: ~">>>";
input::-ms-input-placeholder {
  color: #999;
  font-size: 14px;
}
.search_box {
  display: flex;
  align-items: center;
  width: 560px;
  height: 44px;
  border: 2px solid #2f8ae0;
  border-radius: 22px;
  overflow: hidden;
  .search_left {
    width: 87px;
    height: 100%;
    border-right: 1px solid #ddd;
    text-align: center;
    line-height: 44px;
    cursor: pointer;
    img {
      width: 10px;
      height: 6px;
    }
    .kecheng_by {
      position: absolute;
      z-index: 22;
      width: 68px;
      height: 80px;
      padding-top: 15px;
      box-sizing: border-box;
      // background: url("../../assets/img/home/xl.png") no-repeat;
      background-size: 100%, 100%;
      background-color: #fff;
      box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      border: 1px solid #eee;
      .kecheng_item {
        line-height: 26px;
        width: 91%;
        margin: 0 auto;
      }
    }
  }
  .input_box {
    width: 560px;
    height: 100%;
    border: none;
    outline: none;
    padding: 0 10px;
    box-sizing: border-box;
  }
  .search_btn {
    width: 80px;
    height: 100%;
    line-height: 50px;
    background-color: #0090f0;
    text-align: center;
    cursor: pointer;
    img {
      width: 18px;
      height: 18px;
    }
  }
}
.login_box {
  display: flex;
  align-items: center;
  .msg {
    position: relative;
    margin-right: 50px;
    img {
      width: 18px;
      height: 20px;
      cursor: pointer;
    }
    .msg_body {
      position: absolute;
      top: 22px;
      left: -140px;
      z-index: 23;
      width: 307px;
      height: 280px;
      background: url("../../assets/img/home/bg_1_5.png");
      background-size: 100%;
      padding: 25px;
      box-sizing: border-box;
      .msg_top {
        display: flex;
        font-size: 14px;
        color: #666;
        height: 30px;
        line-height: 30px;
        border-bottom: 1px solid #f2f2f2;
        img {
          width: 14px;
          height: 14px;
          vertical-align: middle;
        }
        > div {
          width: 50%;
          text-align: center;
          cursor: pointer;
        }
      }
      .msg_list {
        padding: 12px 0;
        box-sizing: border-box;
        .msg_item {
          display: flex;
          margin-bottom: 20px;
          cursor: pointer;
          .msg_left {
            div {
              width: 6px;
              height: 6px;
              background: #f13232;
              border-radius: 50%;
              margin-top: 5px;
              // margin-right: 8px;
            }
          }
          .msg_right {
            width: 95%;
            font-size: 12px;
            // color: #0090f0;
            > div {
              width: 100%;
            }
            > div:nth-child(2) {
              color: #999;
              // margin-left: 8px;
              margin-top: 5px;
            }
            span {
              color: #333333;
            }
          }
        }
        .msg_item:last-child {
          margin-bottom: 0;
        }
      }
      .msg_btm {
        border-top: 1px solid #f2f2f2;
        width: 100%;
        height: 38px;
        font-size: 12px;
        text-align: center;
        line-height: 38px;
        cursor: pointer;
      }
    }
  }
  .login {
    font-size: 16px;
    color: 3333;
    span {
      cursor: pointer;
      margin: 0 5px;
    }
  }
  .txImg {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    cursor: pointer;
  }
}

.header_box2 {
  // display: flex;
  // align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 22;
  width: 100%;
  height: 60px;
  background-color: #fff;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
  .header2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .navbar2 {
      display: flex;
      // align-items: center;
      height: 100%;
      line-height: 60px;
      font-size: 16px;
      .box {
        display: flex;
        padding: 0 30px;
        box-sizing: border-box;
      }
      .navbar_item {
        cursor: pointer;
      }
    }
    .search_box2 {
      width: 350px;
      height: 36px;
      border: 1px solid #2f8ae0;
      .search_left {
        width: 70px;
        line-height: 36px;
      }
      .search_btn {
        width: 51px;
        line-height: 43px;
      }
      input {
        width: 230px;
      }
    }
  }
}
.per_box {
  position: relative;
  .txImg {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    cursor: pointer;
  }
  .per_body {
    position: absolute;
    top: 30px;
    left: -55px;
    z-index: 23;
    width: 150px;
    height: 270px;
    background: url("../../assets/img/home/bg_1_6.png");
    background-size: 100%;
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
    font-size: 16px;
    .per_list {
      div {
        height: 48px;
        line-height: 48px;
        cursor: pointer;
      }
    }
    .per_btm {
      border-top: 1px solid #f2f2f2;
      width: 100%;
      height: 50px;
      line-height: 50px;
      cursor: pointer;
    }
  }
}
</style>
<template>
  <div>
    <div class="header_box2">
      <div class="header2 w">
        <img class="logo" @click="goIndex" :src="loginImg" alt="" />
        <!-- <img class="logo" @click="goIndex" src="../../assets/img/login/logo.png" alt="" /> -->
        <!-- 导航 -->
        <div class="navbar2">
          <div class="box" v-for="(item, index) in navbarList2" :key="index">
            <div
              class="navbar_item"
              :class="{ active: active == item.id }"
              @click="changeTabs(item.id)"
            >
              {{ item.title }}
            </div>
          </div>
        </div>
        <!-- 搜索 -->
        <div class="search_box search_box2">
          <div class="search_left">
            <div @click="selectBody = !selectBody">
              {{ selectTit }}
              <img src="../../assets/img/home/icon_1_1.png" alt="" />
            </div>
            <div class="kecheng_by" v-if="selectBody">
              <div
                class="kecheng_item"
                v-for="(item, index) in selectList"
                :key="index"
                :class="{ active2: active2 == index }"
                @mouseenter="active2 = index"
                @mouseleave="active2 = -1"
                @click="changeTit(item)"
              >
                {{ item }}
              </div>
            </div>
          </div>
          <input
            class="input_box"
            type="text"
            placeholder="请输入搜索内容"
            v-model="searchStr"
          />
          <div class="search_btn" @click="goSearch(selectList[active2])">
            <img src="../../assets/img/home/icon_search.png" alt="" />
          </div>
        </div>
        <!-- 登录 -->
        <div class="login_box">
          <div
            class="msg"
            @mouseenter="perFun(0)"
            @mouseleave="msgShow = false"
            v-if="!loginShow"
          >
            <div v-if="msgCount > 0">
              <el-badge :value="msgCount" :max="99" class="item">
                <img src="../../assets/img/home/icon_msg.png" alt />
              </el-badge>
            </div>
            <div v-else>
              <img src="../../assets/img/home/icon_msg.png" alt />
            </div>
            <div class="msg_body" v-if="msgShow">
              <div class="msg_top">
                <div
                  :class="{ msgActive: msgActive == 0 }"
                  @click="changeMsg(0)"
                >
                  <img
                    v-if="msgActive == 0"
                    src="../../assets/img/home/icon_tongzhi2.png"
                    alt
                  />
                  <img
                    v-else
                    src="../../assets/img/home/icon_tongzhi.png"
                    alt
                  />
                  通知
                </div>
                <div
                  :class="{ msgActive: msgActive == 1 }"
                  @click="changeMsg(1)"
                >
                  <img
                    v-if="msgActive == 1"
                    src="../../assets/img/home/icon_gonggao.png"
                    alt
                  />
                  <img
                    v-else
                    src="../../assets/img/home/icon_gonggao2.png"
                    alt
                  />
                  公告
                </div>
              </div>
              <div class="msg_list" v-if="msgList.length > 0">
                <div
                  class="msg_item"
                  v-for="(item, index) in msgList"
                  :key="index"
                  @mouseenter="msgActive2 = index"
                  @mouseleave="msgActive2 = -1"
                  @click="goMsg(item.id, item.is_real)"
                >
                  <div class="msg_left">
                    <div v-if="item.is_real == 0"></div>
                  </div>
                  <div class="msg_right">
                    <div
                      class="over"
                      :class="{ msgActive: msgActive2 == index }"
                    >
                      <!-- <span>【消息】</span> -->
                      {{ item.title }}
                    </div>
                    <div>{{ (item.create_time * 1000) | dateFormat }}</div>
                  </div>
                </div>
              </div>
              <div v-else class="none">暂无消息</div>
              <div v-if="msgList.length > 0" class="msg_btm" @click="goMsg2">
                全部消息
              </div>
            </div>
          </div>
          <div class="login" v-if="loginShow">
            <span @click="goLogin(0)">登录</span>
            &nbsp;&nbsp; | &nbsp;&nbsp;
            <span @click="goLogin(1)">注册</span>
          </div>
          <!-- 个人中心 -->
          <div
            v-else
            class="per_box"
            @mouseenter="perFun(1)"
            @mouseleave="perShow = false"
          >
            <img
              @click="goPersoanl"
              class="txImg t1"
              :src="userInfo.face"
              alt=""
            />
            <div class="per_body" v-if="perShow">
              <div class="per_list">
                <div
                  @mouseenter="msgActive3 = index"
                  @mouseleave="msgActive3 = -1"
                  v-for="(item, index) in per_list"
                  :key="index"
                  :class="{ msgActive: msgActive3 == index }"
                  @click="goPer(index)"
                >
                  {{ item }}
                </div>
              </div>
              <div class="per_btm" @click="goOut">退出</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Bus from "../../bus.js";
import { msgList, msgDetail, msgReal } from "../../assets/js/personal";
import { getLogo } from "../../assets/js/user";
export default {
  data() {
    return {
      loginImg: "",
      URL: "http://v2.hnzye.cn/",
      userInfo: {},
      loginShow: false,
      path: "",
      active: 0,
      active2: 0,
      msgActive: 0,
      msgActive2: -1,
      msgActive3: -1,
      msgShow: false,
      perShow: false,
      per_list: ["我的课程", "我的订单", "学习卡", "账号管理"],
      navbarList2: [
        { id: 0, title: "首页" },
        { id: 1, title: "课程" },
        // { id: 2, title: "直播中心" },
        { id: 3, title: "题库练习" },
        { id: 4, title: "关于我们" },
      ],
      selectList: [
        "课程",
        // "直播"
      ],
      selectTit: "课程",
      selectBody: false,
      allBody: false,
      flag: false,
      allList: [
        {
          title: "建筑工程",
          son: [
            "一级消防工程师",
            "一级建造师",
            "二级建造师",
            "BIM",
            "消防设施操作员",
          ],
        },
        {
          title: "建筑工程",
          son: [
            "一级消防工程师",
            "一级建造师",
            "二级建造师",
            "BIM",
            "消防设施操作员",
          ],
        },
        {
          title: "建筑工程",
          son: [
            "一级消防工程师",
            "一级建造师",
            "二级建造师",
            "BIM",
            "消防设施操作员",
          ],
        },
        {
          title: "建筑工程",
          son: [
            "一级消防工程师",
            "一级建造师",
            "二级建造师",
            "BIM",
            "消防设施操作员",
          ],
        },
      ],
      type: "",
      currentPage: 1,
      size: 3,
      msgList: [],
      searchStr: "",
      msgCount: "",
    };
  },
  mounted() {
    Bus.$on("read", (e) => {
      if (e) {
        this.getMsgList();
      }
    });
    this.path = this.$route.path;
    // console.log(this.$route.path);
    this.active = window.localStorage.getItem("activeHeader");
    this.userInfo = JSON.parse(window.localStorage.getItem("userInfo"));
    if (!this.userInfo) {
      this.loginShow = true;
    } else {
      this.loginShow = false;
    }
    this.type = this.msgActive + 1;
    this.getMsgList();
    getLogo({
      siteId: window.localStorage.getItem("siteId"),
    }).then((res) => {
      // console.log(res);
      if (res.code == 200) {
        this.loginImg = res.data;
      }
    });
  },
  methods: {
    goIndex() {
      this.$router.push("/index");
    },
    // 搜索
    goSearch(item) {
      // console.log(item);
      if (!this.searchStr.trim()) {
        this.$message.error("请输入搜索内容！");
        return;
      }
      if (this.path == "/course" || this.path == "/live") {
        Bus.$emit("msg", this.searchStr);
        if (item == "课程") {
          this.active = 1;
          window.localStorage.setItem("activeHeader", 1);
        } else {
          this.active = 2;
          window.localStorage.setItem("activeHeader", 2);
        }
      }
      // else {
      if (item == "课程") {
        this.$router.push({
          path: "/course",
          query: {
            search: this.searchStr,
          },
        });
        this.active = 1;
        window.localStorage.setItem("activeHeader", 1);
      } else {
        this.$router.push({
          path: "/live",
          query: {
            search: this.searchStr,
          },
        });
        this.active = 2;
        window.localStorage.setItem("activeHeader", 2);
        // }
      }

      this.searchStr = "";
    },
    // 获取消息列表
    getMsgList() {
      msgList({
        siteId: window.localStorage.getItem("siteId"),
        type: this.type,
        user_id: JSON.parse(window.localStorage.getItem("userInfo"))
          ? JSON.parse(window.localStorage.getItem("userInfo")).id
          : "",
        offset: this.currentPage,
        limit: this.size,
      }).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.msgList = res.data.list;
          this.msgCount = res.data.not_real_all;
          // this.msgCount = 12244;
        }
      });
    },
    goMsg(id, isReal) {
      if (id) {
        // Bus.$emit("businessId", id,isReal,true);
        msgDetail({
          a_id: id,
        }).then((res) => {
          if (res.code == 200) {
            window.sessionStorage.setItem(
              "detail",
              JSON.stringify(res.data.info)
            );
            window.sessionStorage.setItem("isReal", isReal);
            window.sessionStorage.setItem("headerMSG", true);
            if (this.$route.path !== "/personal") {
              this.$router.push("/personal");
            }
            window.localStorage.setItem("perHeader", 4);
          }
        });
      }
    },
    goMsg2() {
      if (this.$route.path !== "/personal") {
        this.$router.push("/personal");
      }
      window.localStorage.setItem("perHeader", 4);
    },
    // 退出登录
    goOut() {
      localStorage.clear();
      this.$message.success("退出成功");
      this.$router.push({
        path: "/login",
        query: {
          active: 0,
        },
      });
    },
    changeMsg(i) {
      this.msgActive = i;
      this.currentPage = 1;
      this.type = i + 1;
      this.getMsgList();
    },
    perFun(i) {
      if (this.$route.path == "/personal") {
        if (i == 0) {
          this.msgShow = false;
        } else {
          this.perShow = false;
        }
      } else {
        if (i == 0) {
          this.msgShow = true;
        } else {
          this.perShow = true;
        }
      }
    },
    goPer(i) {
      // console.log(i);
      if (this.$route.path !== "/personal") {
        this.$router.push("/personal");
      }
      if (i == 0 || i == 1) {
        window.localStorage.setItem("perHeader", i);
      } else if (i == 2) {
        window.localStorage.setItem("perHeader", 3);
      } else if (i == 3) {
        window.localStorage.setItem("perHeader", 5);
      }
    },
    goLogin(i) {
      this.$router.push({
        path: "/login",
        query: {
          active: i,
        },
      });
    },
    goPersoanl() {
      if (this.$route.path !== "/personal") {
        this.$router.push("/personal");
      }
      this.active = -1;
      window.localStorage.setItem("activeHeader", -1);
    },
    changeTabs(i) {
      this.active = i;
      window.localStorage.setItem("activeHeader", i);
      if (i == 0) {
        // 首页
        if (this.$route.path !== "/index") {
          this.$router.push("/index");
        }
      } else if (i == 1) {
        // 课程
        if (this.$route.path !== "/course") {
          this.$router.push("/course");
        }
      } else if (i == 2) {
        // 直播中心
        if (this.$route.path !== "/live") {
          this.$router.push("/live");
        }
      } else if (i == 3) {
        // 题库练习
        if (this.$route.path !== "/tiku") {
          this.$router.push("/tiku");
        }
      } else if (i == 4) {
        // 关于我们
        if (this.$route.path !== "/contact") {
          this.$router.push("/contact");
        }
      }
    },
    changeTit(item) {
      this.selectTit = item;
      this.selectBody = false;
    },
  },
};
</script>